import * as api from "@/api/integral";

const integral = {
  namespaced: true,
  state: () => ({
    integralLogs: [],
  }),
  mutations: {
    setIntegralLogs(state, integralLogs) {
      state.integralLogs = integralLogs;
    },
  },
  actions: {
    getIntegralLogs({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .getIntegralLogs(data)
          .then((res) => {
            if (res.code === "ok") {
              commit("setIntegralLogs", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },
  getters: {},
};

export default integral;
