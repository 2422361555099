import * as api from "@/api/notice";

const notice = {
  namespaced: true,
  state: () => ({
    noticeData: [],
  }),
  mutations: {
    setNoticeData(state, noticeData) {
      state.noticeData = noticeData;
    },
  },
  actions: {
    getNotice({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .getNotice()
          .then((res) => {
            if (res.code === "ok") {
              commit("setNoticeData", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },
  getters: {},
};

export default notice;
