import * as api from "@/api/register";
import { Message } from "element-ui";
const register = {
  namespaced: true,
  // state: () => ({
  //   registerData: [],
  // }),
  // mutations: {
  //   setRegister(state, registerData) {
  //     state.registerData = registerData;
  //   },
  // },
  actions: {
    postVisitorRegister(_, data) {
      return new Promise((resolve, reject) => {
        api
          .postVisitorRegister(data)
          .then((res) => {
            Message({
              message: '绑定成功',
              iconClass: "x",
              center: true,
              customClass: "error_message",
            });
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    async postRegister(_, data) {
      try {
        const res = await api.postRegister(data);
        Message({
          message: res.msg,
          iconClass: "x",
          center: true,
          customClass: "error_message",
        });

        return res;
      } catch (err) {
        console.log(err);
        throw err;
      }
    }

  },
  getters: {},
};

export default register;
