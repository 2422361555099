import * as api from "@/api/appeals";

const appeals = {
  namespaced: true,
  state: () => ({
    appealsData: [],
    appealsDataDetail: {},
  }),
  mutations: {
    setAppealsData(state, appealsData) {
      state.appealsData = appealsData;
    },
    setAppealsDataDetail(state, appealsDataDetail) {
      state.appealsDataDetail = appealsDataDetail;
    },
  },
  actions: {
    getAppeals({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .getAppeals(data)
          .then((res) => {
            if (res.code === "ok") {
              commit("setAppealsData", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    addUserAppeals(_, data) {
      return new Promise((resolve, reject) => {
        api.addUserAppeals(data).then(res => {
          console.log(res)
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    addSellerAppeals(_, data) {
      return new Promise((resolve, reject) => {
        api.addSellerAppeals(data).then(res => {
          console.log(res)
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    cancelAppeals(_, data) {
      return new Promise((resolve, reject) => {
        api.cancelAppeals(data).then(res => {
          console.log(res)
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    getSellerAppeals(_, data) {
      return new Promise((resolve, reject) => {
        api.getSellerAppeals(data).then(res => {
          console.log(res)
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    getUserAppeals(_, data) {
      return new Promise((resolve, reject) => {
        api.getUserAppeals(data).then(res => {
          console.log(res)
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    getAppealsDetail({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .getAppealsDetail(data)
          .then((res) => {
            if (res.code === "ok") {
              commit("setAppealsDataDetail", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },
  getters: {},
};

export default appeals;
