import * as api from '@/api/message'
import { tim, TIMContent } from '@/utils/tim'

const message = {
  namespaced: true,
  state: () => ({
    signal: {},
    msgList: [],
    isTimLogin: false,
    nextReqMessageID: null,
    isCompleted: false,
    msgContent: [],
    usersOnlineStatus: {},
  }),
  mutations: {
    setSignal(state, signal) {
      state.signal = signal
    },
    setMsgList(state, msgList) {
      state.msgList = msgList
    },
    setNextReqMessageID(state, nextReqMessageID) {
      state.nextReqMessageID = nextReqMessageID
    },
    setIsCompleted(state, isCompleted) {
      state.isCompleted = isCompleted
    },
    setMsgContent(state, msgContent) {
      state.msgContent = msgContent
    },
    pushMsgContent(state, msgContent) {
      state.msgContent = msgContent.concat(state.msgContent)
    },
    addMsgContent(state, msgContent) {
      state.msgContent = state.msgContent.concat(msgContent)
    },
    setTimLogin(state) {
      state.isTimLogin = true
    },
    setTimLogout(state) {
      state.isTimLogin = false
    },
    setUsersOnlineStatus(state, status) {
      state.usersOnlineStatus = status
    }
  },
  actions: {
    deleteConversation(context, conversationID) {
      let promise = tim.deleteConversation(conversationID);
      promise.then(function (imResponse) {
        // 删除会话成功
        const { conversationID } = imResponse.data; // 被删除的会话 ID
        console.log('deleteConversation 删除会话成功', conversationID)
      }).catch(function (imError) {
        console.warn('deleteConversation error:', imError); // 删除会话失败的相关信息
      });
    },
    getSignalData({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.getSignalData(params).then(res => {
          if (res.code === 'ok') {
            commit('setSignal', res.data)
          }
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    getMsgList({ commit }) {
      tim.getConversationList().then(res => {
        commit('setMsgList', res.data.conversationList)
      })
    },
    setMsgList({ commit }, list) {
      commit('setMsgList', list)
    },
    getMsgContent({ commit }, data) {
      tim.getMessageList(data).then(res => {
        commit('setNextReqMessageID', res.data.nextReqMessageID)
        commit('setIsCompleted', res.data.isCompleted)
        if (data.nextReqMessageID) {
          commit('pushMsgContent', res.data.messageList)
        } else
          commit('setMsgContent', res.data.messageList)
      })
    },
    sendMsg({ commit }, { to, text }) {
      const message = tim.createTextMessage({
        to,
        payload: {
          text
        },
        conversationType: TIMContent.TYPES.CONV_C2C,
      })
      tim.sendMessage(message).then(res => {
        console.log(res)
        commit('addMsgContent', [message])
      })
    },
    sendImg({ commit }, { to, file, onProgress }) {
      const message = tim.createImageMessage({
        to,
        payload: {
          file: file,
        },
        onProgress: onProgress,
        conversationType: TIMContent.TYPES.CONV_C2C,
      })
      tim.sendMessage(message).then(res => {
        console.log(res)
        commit('addMsgContent', [message])
      })
    },
    sendAudio({ commit }, { to, file, onProgress }) {
      const message = tim.createAudioMessage({
        to,
        payload: {
          file: file,
        },
        onProgress: onProgress,
        conversationType: TIMContent.TYPES.CONV_C2C,
      })
      console.log(message)
      tim.sendMessage(message).then(res => {
        console.log(res)
        commit('addMsgContent', [message])
      })
    },
    sendVideo({ commit }, { to, file, onProgress }) {
      const message = tim.createVideoMessage({
        to,
        payload: {
          file: file,
        },
        onProgress: onProgress,
        conversationType: TIMContent.TYPES.CONV_C2C,
      })
      tim.sendMessage(message).then(res => {
        console.log(res)
        commit('addMsgContent', [message])
      })
    },
    addMsgContent({ commit }, list) {
      commit('addMsgContent', list)
    },
    cleanChartInfo({ commit }) {
      commit('setNextReqMessageID', null)
      commit('setIsCompleted', false)
      commit('setMsgContent', [])
    },
    setMessageRead(_, conversationID) {
      tim.setMessageRead({ conversationID })
    },
    setTimLogin({ commit }) {
      commit('setTimLogin')
    },
    setTimeLogout({ commit }) {
      commit('setTimLogout')
    },
    getUsersOnlineStatus({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.getUsersOnlineStatus(params).then(res => {
          if (res.code === 'ok') {
            commit('setUsersOnlineStatus', res.data)
          }
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    }
  },
  getters: {

  }
}

export default message