import axios from "axios";
import cookie from "js-cookie";
import MD5 from "../utils/md5";
import router from "../router";
import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 20000,
});
instance.interceptors.request.use(
  function (request) {
    var timestamp = new Date().getTime();
    request.headers = {
      "Content-Type": "application/json; charset=UTF-8",
      timestamp: timestamp,
      sign: MD5(process.env.VUE_APP_SECRET + timestamp),
      token: cookie.get("token") || "",
    };
    return request;
  },
  function (err) {
    console.log(err.response);
    cookie.remove("token");
    return Promise.reject(err);
  }
);

instance.interceptors.response.use(
  function (response) {
    if (response.data.code === "Unauthorized") {
      cookie.remove("token");
      router.push("/login");
    }
    return response.data;
  },
  function (err) {
    console.log(err);
    return Promise.reject(err);
  }
);

export default instance;
