import axios from "../axios/axios";

export function login(data) {
  return axios.post("/user/login", data);
}

export function loginGoogle(data) {
  return axios.post("/user/login/google", data);
}
export function loginSMS(data) {
  return axios.post("/user/login/validate", data);
}

export function logout() {
  return axios.post("/user/logout");
}

// 取SMS登录 SMS code
export function getLoginSMSCode(params) {
  return axios.get("/user/sms/login/code", { params });
}
