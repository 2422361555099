import * as api from "@/api/problem";

const problem = {
  namespaced: true,
  state: () => ({
    problemData: [],
  }),
  mutations: {
    setProblemData(state, problemData) {
      state.problemData = problemData;
    },
  },
  actions: {
    postProblemLogs({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .postProblemLogs(data)
          .then((res) => {
            if (res.code === "ok") {
              commit("setProblemData", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },
  getters: {},
};

export default problem;
