import * as api from "@/api/account";
import { Message } from 'element-ui';

const account = {
  namespaced: true,
  state: () => ({
    accountData: [],
    msg: ""
  }),
  mutations: {
    setAccountData(state, accountData) {
      state.accountData = accountData;
    },
    setMsg(state, msg) {
      state.msg = msg;
    }
  },
  actions: {
    getAccounts({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .getAccounts(data)
          .then((res) => {
            if (res.code === "ok") {
              commit("setAccountData", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    updateAccount({ commit }, { id, status }) {
      let data = {
        status: status
      }
      return new Promise((resolve, reject) => {
        api
          .updateAccount(id, data)
          .then((res) => {
            Message({
              message: res.msg,
              iconClass: 'x',
              center: true,
              customClass: 'error_message',
            })
            commit('setMsg', res.msg)
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    deleteAccount({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        api
          .deleteAccount(id)
          .then((res) => {
            commit('setMsg', res)
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    postAccount({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .postAccount(data)
          .then((res) => {
            Message({
              message: res.msg,
              iconClass: 'x',
              center: true,
              customClass: 'error_message',
            })
            commit('setMsg', res.msg)
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },
  getters: {},
};

export default account;
