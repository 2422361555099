import * as api from "@/api/buy";
import { Message } from 'element-ui';
const buy = {
  namespaced: true,
  state: () => ({
    buyData: [],
    buyFastTradersData: []
  }),
  mutations: {
    setBuyData(state, buyData) {
      state.buyData = buyData;
    },
    setBuyFastTradersData(state, buyFastTradersData) {
      state.buyFastTradersData = buyFastTradersData;
    }
  },
  actions: {
    getBuyTraders({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .getBuyTraders(data)
          .then((res) => {
            if (res.code === "ok") {
              commit("setBuyData", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    getBuyFastTrader({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .getBuyFastTrader(data)
          .then((res) => {
            Message({
              message: res.msg,
              iconClass: 'x',
              center: true,
              customClass: 'sucess_message',
            })
            if (res.code === "ok") {
              commit("setBuyFastTradersData", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },
  getters: {},
};

export default buy;
