import * as api from "@/api/config";

const config = {
  namespaced: true,
  state: () => ({
    configData: [],
    kefu: "https://messenger.melumisc.com/messenger/2a1sg7zw69ifrrccqinb4jxebb.html"
  }),
  mutations: {
    setConfigData(state, configData) {
      state.configData = configData;
    }
  },
  actions: {
    getUserAndroidVersion({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .getUserAndroidVersion(data)
          .then((res) => {
            if (res.code === "ok") {
              commit("setConfigData", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    getUserIOSVersion({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .getUserIOSVersion(data)
          .then((res) => {
            if (res.code === "ok") {
              commit("setConfigData", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    }
  },
  getters: {}
};

export default config;
