import * as api from "@/api/products";
import router from "@/router/index"
import { Message } from 'element-ui';
const products = {
  namespaced: true,
  state: () => ({
    productsData: [],
    productsIdData: []
  }),
  mutations: {
    setProductsData(state, productsData) {
      state.productsData = productsData;
    },
    setProductsIdData(state, productsIdData) {
      state.productsIdData = productsIdData;
    }
  },
  actions: {
    getProducts({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .getProducts(data)
          .then((res) => {
            if (res.code === "ok") {
              commit("setProductsData", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    postProductsId({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .postProductsId(data)
          .then((res) => {
            Message({
              message: res.msg,
              iconClass: 'x',
              center: true,
              customClass: 'sucess_message',
            })
            if (res.code === "ok") {
              commit("setProductsIdData", res.data)
              router.push("/")
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },
  getters: {},
};

export default products;
