import * as api from "@/api/oss";

const oss = {
  namespaced: true,
  state: () => ({
  }),
  mutations: {
    setDict(state, dict) {
      state.dict = dict;
    }
  },
  actions: {
    getOssUrl(_, data) {
      return new Promise((resolve, reject) => {
        api
          .getOssUrl(data)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    uploadImg(_, data) {
      return new Promise((resolve, reject) => {
        api.uploadImg(data).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    }
  },
  getters: {},
};

export default oss;
