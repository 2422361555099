import * as api from "@/api/auth";
import cookie from "js-cookie";
import router from "../router";
import { Message } from "element-ui";
const auth = {
  namespaced: true,
  state: () => ({
    token: cookie.get("token"),
    googleOTPInfo: {},
    user: {
      userMobile: ""
    }
  }),
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setGoogleOTPInfo(state, data) {
      state.googleOTPInfo = data;
    },
    setUserMobile(state, userMobile) {
      state.user.userMobile = userMobile;
    }
  },
  actions: {
    login({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .login(data)
          .then((res) => {
            Message({
              message: res.msg,
              iconClass: "x",
              center: true,
              customClass: "error_message", duration: 500
            });
            if (res.code === "ok") {
              commit("setUserMobile", data.userMobile);
              const googleOTPInfo = {
                androidGoogleAuthenticatorLink:
                  res.data.androidGoogleAuthenticatorLink,
                googleSecret: res.data.googleSecret,
                googleSecretQrcode: res.data.googleSecretQrcode,
                loginType: res.data.loginType
              };
              cookie.set("token", res.data.token);
              commit("setGoogleOTPInfo", googleOTPInfo);
              commit("setToken", res.data.token);
              if (router.history.current.query.payMoney == 1) {
                router.push({
                  path: "/my/index",
                  query: {
                    orderNo: router.history.current.query.orderNo
                  }
                });
              } else {
                if (cookie.get("token") != "") {
                  router.push("/");
                }
              }
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    getLoginSMSCode(_, userMobile) {
      return new Promise((resolve, reject) => {
        api
          .getLoginSMSCode({ userMobile })
          .then((res) => {
            Message({
              message: res.msg,
              iconClass: "x",
              center: true,
              customClass: "error_message", duration: 1000
            });
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    googleSecondConfrim({ state }, code) {
      return new Promise((resolve, reject) => {
        const loginInfo = {
          code: code,
          googleSecret: state.googleOTPInfo.googleSecret,
          userMobile: state.user.userMobile
        };
        api
          .loginGoogle(loginInfo)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    loginSMS({ commit, state }, data) {
      console.log(commit, state)
      return new Promise((resolve, reject) => {
        const loginInfo = {
          userMobile: data.userMobile,
          verifyCode: data.verifyCode,
          securityCode: data.securityCode,
          device: 2,
          deviceNo: data.deviceNo
        };
        api
          .loginSMS(loginInfo)
          .then((res) => {
            if (res.code === "ok") {
              commit("setToken", res.data.token);
              cookie.set("token", res.data.token);
              console.log(res)
            } else {
              Message({
                message: res.msg,
                iconClass: "x",
                center: true,
                customClass: "error_message"
              });
              // setTimeout(() => {
              //   location.reload();
              // }, 500);
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    googleLogin({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        const loginInfo = {
          code: data.code,
          googleSecret: state.googleOTPInfo.googleSecret,
          userMobile: data.userMobile
        };
        api
          .loginGoogle(loginInfo)
          .then((res) => {
            if (res.code === "ok") {
              commit("setToken", res.data.token);
              cookie.set("token", res.data.token);
            } else {
              Message({
                message: res.msg,
                iconClass: "x",
                center: true,
                customClass: "error_message"
              });
              setTimeout(() => {
                location.reload();
              }, 500);
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    googleCheckGoogleSecret({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .loginGoogle(data)
          .then((res) => {
            if (res.code === "ok") {
              commit("setToken", res.data.token);
              cookie.set("token", res.data.token);
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .logout()
          .then((res) => {
            if (res.code === "ok") {
              commit("setGoogleOTPInfo", "");
              commit("setToken", "");
              cookie.remove("token");
              router.push("/login");
              location.reload();
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    }
  },
  getters: {}
};

export default auth;
