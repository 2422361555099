import * as api from "@/api/sell";

const sell = {
  namespaced: true,
  state: () => ({
    sellTradersData: [],
  }),
  mutations: {
    setSellData(state, sellTradersData) {
      state.sellTradersData = sellTradersData;
    },
  },
  actions: {
    getSellTraders({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .getSellTraders(data)
          .then((res) => {
            if (res.code === "ok") {
              commit("setSellData", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },
  getters: {},
};

export default sell;
