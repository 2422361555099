import axios from '../axios/axios'

export function getUserAndroidVersion() {
  return axios.get('/user/android/version')
}

export function getUserIOSVersion() {
  return axios.get('/user/ios/version')
}

export function getUserConfig() {
  return axios.get('/user/config')
}

export function getTraderConfig() {
  return axios.get('/trader/config')
}
