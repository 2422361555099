import * as api from "@/api/amount";

const amount = {
  namespaced: true,
  state: () => ({
    amountLogs: [],
  }),
  mutations: {
    setAmountLogs(state, amountLogs) {
      state.amountLogs = amountLogs;
    },
  },
  actions: {
    getAmountLogs({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .getAmountLogs(data)
          .then((res) => {
            if (res.code === "ok") {
              commit("setAmountLogs", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },
  getters: {},
};

export default amount;
