import * as api from "@/api/orders";
import { Message } from "element-ui";
const orders = {
  namespaced: true,
  state: () => ({
    ordersData: [],
    buySubmitData: [],
    withdrawSubmitData: [],
    countdown: "",
    logData: [],
    logListData: [],
    logListDataToFooter: [],
    buySellerSubmitData: [],
    withdrawCheckoutData: {},
    confirmData: [],
    orderNo: ''
  }),
  mutations: {
    setOrdersData(state, ordersData) {
      state.ordersData = ordersData;
    },
    setLogData(state, logData) {
      state.logData = logData;
    },
    setLogListData(state, logListData) {
      state.logListData = logListData;
    },
    setLogListDataToFooter(state, logListDataToFooter) {
      state.logListDataToFooter = logListDataToFooter;
    },
    setBuySubmitData(state, buySubmitData) {
      state.buySubmitData = buySubmitData;
    },
    setBuyWithdrawSubmit(state, withdrawSubmitData) {
      state.withdrawSubmitData = withdrawSubmitData;
    },
    setBuyWithdrawCheckout(state, withdrawCheckoutData) {
      state.withdrawCheckoutData = withdrawCheckoutData;
    },
    setCountdown(state, countdown) {
      state.countdown = countdown;
    },
    setBuySellerSubmitData(state, buySellerSubmitData) {
      state.buySellerSubmitData = buySellerSubmitData;
    },
    setConfirmData(state, confirmData) {
      state.confirmData = confirmData;
    },
    setOrderNo(state, orderNo) {
      state.orderNo = orderNo;
    }
  },
  actions: {
    setOrderNo({ commit }, orderNo) {
      commit('setOrderNo', orderNo);
    },
    getOrders({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .getOnlineQuery(data)
          .then((res) => {
            if (res.code === "ok") {
              commit("setOrdersData", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    getUploadImgData(_, params) {
      return new Promise((resolve, reject) => {
        api.getUploadImgData(params).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getLog({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .getLog(data)
          .then((res) => {
            if (res.code === "ok") {
              commit("setLogListData", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    getLogToFooter({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .getLog(data)
          .then((res) => {
            if (res.code === "ok") {
              commit("setLogListDataToFooter", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    getLogId({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .getLogId(data)
          .then((res) => {
            if (res.code === "ok") {
              commit("setLogData", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    getCountdown({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .getCountdown(data)
          .then((res) => {
            if (res.code === "ok") {
              commit("setCountdown", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    postBuySubmit({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .postBuySubmit(data)
          .then((res) => {
            Message({
              message: res.msg,
              iconClass: "x",
              center: true,
              customClass: "sucess_message"
            });
            if (res.code === "ok") {
              commit("setBuySubmitData", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    postWithdrawSubmit({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .postWithdrawSubmit(data)
          .then((res) => {
            Message({
              message: res.msg,
              iconClass: "x",
              center: true,
              customClass: "sucess_message"
            });
            if (res.code === "ok") {
              commit("setBuyWithdrawSubmit", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    postWithdrawCheckout({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .postWithdrawCheckout(data)
          .then((res) => {
            Message({
              message: res.msg,
              iconClass: "x",
              center: true,
              customClass: "sucess_message"
            });
            if (res.code === "ok") {
              commit("setBuyWithdrawCheckout", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    putBuyCancel({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .putBuyCancel(data)
          .then((res) => {
            Message({
              message: res.msg,
              iconClass: "x",
              center: true,
              customClass: "sucess_message"
            });
            if (res.code === "ok") {
              console.log(commit);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    putBuySellerSubmit({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .putBuySellerSubmit(data)
          .then((res) => {
            Message({
              message: res.msg,
              iconClass: "x",
              center: true,
              customClass: "sucess_message"
            });
            if (res.code === "ok") {
              commit("setBuySellerSubmitData", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    putBuyConfirm({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .putBuyConfirm(data)
          .then((res) => {
            Message({
              message: res.msg,
              iconClass: "x",
              center: true,
              customClass: "sucess_message"
            });
            if (res.code === "ok") {
              commit("setConfirmData", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    updateOnlinePay({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .updateOnlinePay(data)
          .then((res) => {
            if (res.code === "ok") {
              commit("setOrdersData", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    }
  },
  getters: {}
};

export default orders;
