import * as api from '@/api/google'

const google = {
  namespaced: true,
  state: () => ({
    googleData: {}
  }),
  mutations: {
    setGoogleData(state, googleData) {
      state.googleData = googleData
    },
  },
  actions: {
    openGoogleSecret({ commit }, data) {
      return new Promise((resolve, reject) => {
        api.openGoogleSecret(data).then(res => {
          if (res.code === 'ok') {
            commit('setGoogleData', res.data)
          }
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    closedGoogleSecret({ commit }, data) {
      return new Promise((resolve, reject) => {
        api.closedGoogleSecret(data).then(res => {
          if (res.code === 'ok') {
            commit('setGoogleData', res.data)
          }
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    }
  },
  getters: {

  }
}

export default google