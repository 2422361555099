import * as api from "@/api/trader";
import { Message } from 'element-ui';
const trader = {
  namespaced: true,
  state: () => ({
    traderRelationsData: [],
  }),
  mutations: {
    setTraderRelationsData(state, traderRelationsData) {
      state.traderRelationsData = traderRelationsData;
    },
  },
  actions: {
    getTraderRelations({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .getTraderRelations(data)
          .then((res) => {
            if (res.code === "ok") {
              commit("setTraderRelationsData", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    postTraderRelations(context, data) {
      return new Promise((resolve, reject) => {
        api
          .postTraderRelations(data)
          .then((res) => {
            if (res.code === "ok") {
              Message({
                message: res.msg,
                iconClass: 'x',
                center: true,
                customClass: 'sucess_message',
              })
              context.dispatch('getTraderRelations')
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    changeTraderRelations(context, data) {
      return new Promise((resolve, reject) => {
        api
          .postTraderRelations(data)
          .then((res) => {
            Message({
              message: res.msg,
              iconClass: 'x',
              center: true,
              customClass: 'error_message',
            })
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    deleteTraderRelations(context, data) {
      console.log('deleteTraderRelations')
      return new Promise((resolve, reject) => {
        api
          .deleteTraderRelations(data)
          .then((res) => {
            Message({
              message: res.msg,
              iconClass: 'x',
              center: true,
              customClass: 'error_message',
            })
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    getTraders({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .getTraders(data)
          .then((res) => {
            if (res.code === "ok") {
              commit("setTraderRelationsData", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },
  getters: {},
};

export default trader;
