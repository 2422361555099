import axios from '../axios/axios'

export function getAccounts(params) {
  return axios.get('/user/accounts', { params })
}

export function updateAccount(id, data) {
  return axios.put(`/user/account/${id}`, data)
}

export function deleteAccount(id) {
  return axios.delete(`/user/account/${id}`)
}

export function postAccount(data) {
  return axios.post('/user/account', data)
}
