import axios from '../axios/axios'

export function getTraderRelations(params) {
  return axios.get('/user/trader/relations', {params})
}
export function postTraderRelations(params) {
  return axios.post('/user/trader/relations', params)
}
export function deleteTraderRelations(id) {
  return axios.delete('/user/trader/relations/'+ id)
}
export function getTraders(id) {
  return axios.get('/user/traders/'+ id)
}

