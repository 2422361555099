import axios from '../axios/axios'

export function getMsg(params) {
  return axios.get('/user/msg', { params })
}

export function getMsgById(id) {
  return axios.get(`/user/msg/${id}`)
}

export function getMsgStatus() {
  return axios.get('/user/msg/status')
}