import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import help from "./help";
import orders from "./orders";
import notice from "./notice";
import buy from "./buy";
import sell from "./sell";
import posters from "./posters";
import trader from "./trader";
import users from "./users";
import products from "./products";
import withdraw from "./withdraw";
import amount from "./amount";
import integral from "./integral";
import spinner from "./spinner";
import msg from "./msg";
import header from "./header";
import loginLogs from "./loginLogs";
import problem from "./problem";
import config from "./config";
import appeals from "./appeals";
import account from "./account";
import security from "./security";
import register from "./register";
import sms from "./sms";
import password from "./password";
import google from "./google";
import message from "./message";
import dict from "./dict";
import oss from "./oss";
import visitorUnauthorized from "./visitorUnauthorized"
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    help,
    notice,
    orders,
    buy,
    sell,
    posters,
    trader,
    users,
    products,
    withdraw,
    amount,
    integral,
    spinner,
    msg,
    header,
    loginLogs,
    problem,
    config,
    account,
    security,
    appeals,
    register,
    sms,
    password,
    google,
    message,
    dict,
    oss,
    visitorUnauthorized
  },
});
