import axios from "../axios/axios";

export function getSmsRegisterCode(params) {
  return axios.get("/user/sms/register/code", { params });
}
export function getSmsVisitorRegisterCode(params) {
  return axios.get("/user/visitor/sms/register/code", { params });
}
export function getSmsPasswordCode(params) {
  return axios.get("/user/sms/password/code", { params });
}
export function putSmsPasswordCode(params) {
  return axios.put("/user/sms/password/code", params);
}

export function getSmsSecurityCode(params) {
  return axios.get("/user/sms/security/code", { params });
}

export function putSmsSecurityCode(params) {
  return axios.put("/user/sms/security/code", params);
}
