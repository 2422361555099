import axios from "../axios/axios";
// <游戏充值>,查询待支付订单
export function getOnlineQuery(params) {
  return axios.get("/user/orders/online-query", { params });
}
// 查询订单记录
export function getLog(params) {
  return axios.get("/user/orders/log", { params });
}
// 根据订单ID查询订单详情
export function getLogId(params) {
  return axios.get("/user/orders/log/" + params);
}
// 查询超时倒计时信息
export function getCountdown(params) {
  return axios.get("/user/orders/countdown", { params });
}
// 用户充值余额<选择常驻用户下单>
export function postBuySubmit(params) {
  return axios.post("/user/orders/buy/submit", params);
}
// 余额提现<选择常驻用户下单>
export function postWithdrawSubmit(params) {
  return axios.post("/user/orders/withdraw/submit", params);
}
// 余额提现<检测可提现余额是否足够>
export function postWithdrawCheckout(params) {
  return axios.post("/user/orders/withdraw/checkout", params);
}
// 用户充值余额<取消交易>
export function putBuyCancel(params) {
  return axios.put("/user/orders/buy/cancel", params);
}
// 用户充值余额<用户点击我已付款>
export function putBuySellerSubmit(params) {
  return axios.put("/user/orders/buy/seller/submit", params);
}
// <余额提现><确认到账订单完成>
export function putBuyConfirm(params) {
  return axios.put("/user/orders/confirm", params);
}
{/* 我的<QRCode扫描，确认付款> */ }
export function updateOnlinePay(data) {
  return axios.put('/user/orders/online-pay', data)
}
// 取得上传截图资讯
export function getUploadImgData(params) {
  return axios.get('/user/ossUrl', { params })
}
