import * as api from "@/api/loginLogs";

const loginLogs = {
  namespaced: true,
  state: () => ({
    loginLogsData: []
  }),
  mutations: {
    setLoginLogsData(state, loginLogsData) {
      state.loginLogsData = loginLogsData;
    }
  },
  actions: {
    getLoginLogs({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .getLoginLogs(data)
          .then((res) => {
            if (res.code === "ok") {
              commit("setLoginLogsData", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    }
  },
  getters: {},
};

export default loginLogs;
