const header = {
    namespaced: true,
    state: () => ({
        headerTitle: '',
    }),
    mutations: {
        setHeaderTitle(state, data) {
            state.headerTitle = data;
        },
    },
    actions: {
        changeHeader({ commit }, data) {
            commit("setHeaderTitle", data);
        }
    },
};

export default header;
