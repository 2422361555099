import * as api from '@/api/help'

const help = {
  namespaced: true,
  state: () => ({
    helpData: []
  }),
  mutations: {
    setHelpData(state, helpData) {
      state.helpData = helpData
    },
  },
  actions: {
    getHelp({ commit }, data) {
      return new Promise((resolve, reject) => {
        api.getHelp(data).then(res => {
          if (res.code === 'ok') {
            commit('setHelpData', res.data)
          }
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    }
  },
  getters: {

  }
}

export default help