import axios from '../axios/axios'


/**
 * name: 图片名称和路径
 * sample - Appeal_img/yyyy-MM-dd/UUID+fileName
 */
export function getOssUrl(params) {
  return axios.get('/user/ossUrl', {params})
}

export function uploadImg({fileUpUrl, contentType, file}) {
  return axios.put(fileUpUrl, file, {headers:{contentType}})
}