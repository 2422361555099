const myeRouter = {
    path: '/my',
    name: 'myDefault',
    meta: { name: '我的' },
    component: () => import('@/views/my'),
    redirect: '/my/index',
    children: [
        {
            path: 'index',
            name: 'my',
            meta: { name: '我的' },
            component: () => import('@/views/my/my/index.vue'),
        },
        {
            path: 'msg',
            name: 'msg',
            meta: { name: '消息通知' },
            components: {
                default: () => import('@/views/my/msg/index.vue'),
                Header: () => import('@/components/Header'),
            },
            redirect: '/my/msg',
            children: [{
                path: '',
                name: 'msgAnnounce',
                meta: { name: '消息通知' },
                component: () => import('@/views/my/msg/msg.vue'),
            }, {
                path: 'system',
                name: 'system',
                meta: { name: '系统消息' },
                component: () => import('@/views/my/msg/msgSystem.vue'),
            }, {
                path: 'personal',
                name: 'personal',
                meta: { name: '个人消息' },
                component: () => import('@/views/my/msg/msgSystem.vue'),
            }, {
                path: 'msgContent',
                name: 'msgContent',
                meta: { name: '' },
                component: () => import('@/views/my/msg/msgContent.vue'),
            }]
        },
        {
            path: 'setting',
            name: 'settingDefault',
            meta: { name: '设置' },
            components: {
                default: () => import('@/views/my/setting/index.vue'),
                Header: () => import('@/components/Header'),
            },
            redirect: '/my/setting',
            children: [{
                path: '',
                name: 'setting',
                meta: { name: '设置' },
                component: () => import('@/views/my/setting/setting.vue'),
            }, {
                path: 'loginLogs',
                name: 'loginLogs',
                meta: { name: '登录历史' },
                component: () => import('@/views/my/setting/loginLogs.vue'),
            }, {
                path: 'problem',
                name: 'problem',
                meta: { name: '意见反馈' },
                component: () => import('@/views/my/setting/problem.vue'),
            }]
        },
        {
            path: 'userInfo',
            name: 'user',
            meta: { name: '个人信息' },
            components: {
                default: () => import('@/views/my/userInfo/index.vue'),
                // Header: () => import('@/components/Header'),
            },
            redirect: '/my/userInfo',
            children: [{
                path: '',
                name: 'userInfo',
                meta: { name: '个人信息' },
                component: () => import('@/views/my/userInfo/userInfo.vue'),
            }, {
                path: 'nickName',
                name: 'nickName',
                meta: { name: '设置昵称' },
                component: () => import('@/views/my/userInfo/nickName.vue'),
            }]
        },
        {
            path: 'myPayment',
            name: 'myPayment',
            meta: { name: '我的账单' },
            components: {
                default: () => import('@/views/my/myPayment'),
                Header: () => import('@/components/Header'),
            }
        },
        {
            path: 'paymentMethod',
            name: 'payment',
            meta: { name: '收款通道' },
            components: {
                default: () => import('@/views/my/paymentMethod/index.vue'),
                // Header: () => import('@/components/Header'),
            },
            redirect: '/my/paymentMethod',
            children: [{
                path: '',
                name: 'paymentMethod',
                meta: { name: '收款通道' },
                component: () => import('@/views/my/paymentMethod/paymentMethod.vue'),
            }, {
                path: 'payment',
                name: 'paymentChannel',
                meta: { name: '' },
                component: () => import('@/views/my/paymentMethod/method.vue'),
            }, {
                path: 'security',
                name: 'security',
                meta: { name: '安全密码' },
                component: () => import('@/views/my/paymentMethod/security.vue')
            }, {
                path: 'help',
                name: 'help',
                meta: { name: '教程攻略' },
                component: () => import('@/views/my/paymentMethod/help.vue')
            }, {
                path: 'addPayment',
                name: 'addPayment',
                meta: { name: '新增支付' },
                component: () => import('@/views/my/paymentMethod/addPayment.vue')
            }]
        },
        {
            path: 'pointsDetails',
            name: 'pointsDetails',
            meta: { name: '积分明细' },
            components: {
                default: () => import('@/views/my/pointsDetails'),
                Header: () => import('@/components/Header'),
            },
        }, {
            path: 'myCollection',
            name: 'myCollectionDefault',
            meta: { name: '我的收藏' },
            components: {
                default: () => import('@/views/my/myCollection/index.vue'),
                Header: () => import('@/components/Header'),
            },
            redirect: '/my/myCollection',
            children: [{
                path: '',
                name: 'myCollection',
                meta: { name: '我的收藏' },
                component: () => import('@/views/my/myCollection/traders.vue'),
            }, {
                path: 'trader',
                name: 'trader',
                meta: { name: '常驻用户信息' },
                component: () => import('@/views/my/myCollection/traderInfo.vue'),
            }]
        }, {
            path: 'securityCenter',
            name: 'securityDefault',
            meta: { name: '安全中心' },
            components: {
                default: () => import('@/views/my/securityCenter/index.vue'),
                Header: () => import('@/components/Header'),
            },
            redirect: '/my/securityCenter',
            children: [{
                path: '',
                name: 'securityCenter',
                meta: { name: '安全中心', step: '/my' },
                component: () => import('@/views/my/securityCenter/securityCenter.vue'),
            }, {
                path: 'setPassword',
                name: 'setPassword',
                meta: { name: '' },
                component: () => import('@/views/my/securityCenter/setPassword.vue'),
            }, {
                path: 'setGoogleAuthenticator',
                name: 'setGoogleAuthenticator',
                meta: { name: '' },
                component: () => import('@/views/my/securityCenter/setGoogleAuthenticator.vue'),
            }, {
                path: 'resetPaymentPassword',
                name: 'resetPaymentPassword',
                meta: { name: '重置安全密码', showHeader: false },
                component: () => import('@/views/my/securityCenter/resetPaymentPassword.vue'),
            }, {
                path: 'googleAuthenticatorContent',
                name: 'googleAuthenticatorContent',
                meta: { name: '谷歌验证教程' },
                component: () => import('@/views/my/securityCenter/googleAuthenticatorContent.vue'),
            }, {
                path: 'resetPassword',
                name: 'resetPassword',
                meta: { name: '重置密码', showHeader: false },
                component: () => import('@/views/bindOTP/resetPassword.vue'),
            }]
        },
        {
            path: 'helpCenter',
            name: 'helpCenterDefault',
            meta: { name: '帮助中心' },
            components: {
                default: () => import('@/views/my/helpCenter/index.vue'),
                Header: () => import('@/components/Header'),
            },
            redirect: '/my/helpCenter',
            children: [{
                path: '',
                name: 'helpCenter',
                meta: { name: '帮助中心' },
                component: () => import('@/views/my/helpCenter/helpCenter.vue'),
            }, {
                path: 'helpContent',
                name: 'helpContent',
                meta: { name: '' },
                component: () => import('@/views/my/helpCenter/helpContent.vue'),
            }]
        }, {
            path: 'aboutUs',
            name: 'aboutUs',
            meta: { name: '关于我们' },
            components: {
                default: () => import('@/views/my/aboutUs'),
                Header: () => import('@/components/Header'),
            }
        }
    ]
}
export default myeRouter