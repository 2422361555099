import * as api from "@/api/security";
import { Message } from 'element-ui';

const security = {
	namespaced: true,
	state: () => ({
		securityData: [],
	}),
	mutations: {
		setSecurityData(state, securityData) {
			state.securityData = securityData;
		},
	},
	actions: {
		postSecurity({ commit }, data) {
			return new Promise((resolve, reject) => {
				api
					.postSecurity(data)
					.then((res) => {
						if (res.code === "ok") {
							commit("setSecurityData", res.data);
						}
						Message({
							message: res.msg,
							iconClass: 'x',
							center: true,
							customClass: 'error_message',
						})
						resolve(res);
					})
					.catch((err) => {
						console.log(err);
						reject(err);
					});
			});
		},
		updateSecurity({ commit }, data) {
			return new Promise((resolve, reject) => {
				api
					.updateSecurity(data)
					.then((res) => {
						if (res.code === "ok") {
							commit("setSecurityData", res.data);
						}
						Message({
							message: res.msg,
							iconClass: 'x',
							center: true,
							customClass: 'error_message',
						})
						resolve(res);
					})
					.catch((err) => {
						console.log(err);
						reject(err);
					});
			});
		}
	},
	getters: {},
};

export default security;
