import * as api from "@/api/msg";

const msg = {
  namespaced: true,
  state: () => ({
    msgData: [],
    num: null
  }),
  mutations: {
    setMsgData(state, msgData) {
      state.msgData = msgData;
    },
    setMsg(state, data) {
      let { num } = data
      state.num = num;
    }
  },
  actions: {
    getMsg({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .getMsg(data)
          .then((res) => {
            if (res.code === "ok") {
              commit("setMsgData", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    getMsgById({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .getMsgById(id)
          .then((res) => {
            if (res.code === "ok") {
              commit("setMsgData", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    getMsgStatus({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .getMsgStatus()
          .then((res) => {
            if (res.code === "ok") {
              commit("setMsg", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    }
  },
  getters: {},
};

export default msg;
