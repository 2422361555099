import * as api from "@/api/password";
import cookie from 'js-cookie'
import { Message } from 'element-ui';
const password = {
  namespaced: true,
  state: () => ({
    passwordData: {}
  }),
  mutations: {
    setPasswordData(state, passwordData) {
      state.passwordData = passwordData;
    }
  },
  actions: {
    updatePassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .updatePassword(data)
          .then((res) => {
            if (res.code === "ok") {
              commit("setPasswordData", res);
              cookie.remove('token')
            }
            Message({
              message: res.msg,
              iconClass: 'x',
              center: true,
              customClass: 'error_message',
            })

            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    }
  },
  getters: {},
};

export default password;
