import * as api from "@/api/withdraw";
import { Message } from "element-ui";
const sell = {
  namespaced: true,
  state: () => ({
    withdrawFastTradersData: []
  }),
  mutations: {
    setWithdrawFastTraders(state, withdrawFastTradersData) {
      state.withdrawFastTradersData = withdrawFastTradersData;
    }
  },
  actions: {
    getWithdrawFastTraders({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .getWithdrawFastTraders(data)
          .then((res) => {
            Message({
              message: res.msg,
              iconClass: "x",
              center: true,
              customClass: "error_message"
            });
            if (res.code === "ok") {
              commit("setWithdrawFastTraders", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    }
  },
  getters: {}
};

export default sell;
