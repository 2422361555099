import axios from '../axios/axios'

export function getAppeals(params) {
  return axios.get('/user/appeals', { params })
}
// 買家发起申诉
export function addUserAppeals(params) {
  return axios.post('/user/appeals', params)
}
// 賣家发起申诉
export function addSellerAppeals(params) {
  return axios.post('/seller/appeals', params)
}

// 撤消申诉
export function cancelAppeals(appealNo) {
  return axios.put(`/user/appeals/${appealNo}`)
}

// 申诉详情
export function getAppealsDetail(appealNo) {
  return axios.get(`/user/appeals/order/${appealNo}`)
}

// 賣家撤销申诉单API
export function getSellerAppeals(appealNo) {
  return axios.put(`/seller/appeals/${appealNo}`)
}

// 買家撤销申诉单API
export function getUserAppeals(appealNo) {
  return axios.put(`/user/appeals/${appealNo}`)
}



