import axios from "../axios/axios";

export function getSignalData(params) {
  return axios.get("/user/im/gen-sig", { params });
}

export function getUsersOnlineStatus(params) {
  return axios.get("/user/im/online", { params });
}

//kick user tim by userId
export function kickUserTim(id) {
  return axios.put('/user/im/kick/' + id)
}
