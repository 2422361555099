import * as api from "@/api/sms";
import { Message } from "element-ui";
const sms = {
  namespaced: true,
  state: () => ({
    smsRegisterCodeData: [],
    smsPasswordCodeData: [],
  }),
  mutations: {
    setSmsRegisterCodeData(state, smsRegisterCodeData) {
      state.smsRegisterCodeData = smsRegisterCodeData;
    },
    setSmsPasswordCodeData(state, smsPasswordCodeData) {
      state.smsPasswordCodeData = smsPasswordCodeData;
    },
  },
  actions: {
    getSmsVisitorRegisterCode(_, data) {
      return new Promise((resolve, reject) => {
        api
          .getSmsVisitorRegisterCode(data)
          .then((res) => {
            Message({
              message: res.msg,
              iconClass: "x",
              center: true,
              customClass: "error_message",
            });
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    getSmsRegisterCode({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .getSmsRegisterCode(data)
          .then((res) => {
            Message({
              message: res.msg,
              iconClass: "x",
              center: true,
              customClass: "error_message",
            });
            if (res.code === "ok") {
              commit("setSmsRegisterCodeData", res);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    getSmsPasswordCode({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .getSmsPasswordCode(data)
          .then((res) => {
            Message({
              message: res.msg,
              iconClass: "x",
              center: true,
              customClass: "error_message",
            });
            if (res.code === "ok") {
              console.log(commit);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    getSmsSecurityCode({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .getSmsSecurityCode(data)
          .then((res) => {
            Message({
              message: res.msg,
              iconClass: "x",
              center: true,
              customClass: "error_message",
            });
            if (res.code === "ok") {
              console.log(commit);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    putSmsPasswordCode({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .putSmsPasswordCode(data)
          .then((res) => {
            Message({
              message: res.msg,
              iconClass: "x",
              center: true,
              customClass: "error_message",
            });
            if (res.code === "ok") {
              commit("setSmsPasswordCodeData", res);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    putSmsSecurityCode({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .putSmsSecurityCode(data)
          .then((res) => {
            Message({
              message: res.msg,
              iconClass: "x",
              center: true,
              customClass: "error_message",
            });
            if (res.code === "ok") {
              commit("setSmsPasswordCodeData", res);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },
  getters: {},
};

export default sms;
