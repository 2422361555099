import TIM from "tim-js-sdk";
import TIMUploadPlugin from "tim-upload-plugin";
import store from "../store";
import router from "../router";

const options = {
  SDKAppID: Number(process.env.VUE_APP_SDKAPPID),
};

export const TIMContent = TIM;
export var tim = TIM.create(options);

async function init(data) {
  tim.setLogLevel(Number(process.env.VUE_APP_TIM_LOG_LEVEL));
  tim.registerPlugin({ "tim-upload-plugin": TIMUploadPlugin });
  tim
    .login(data)
    .then((res) => {
      console.log(res);
      if (res.data.actionStatus === "OK") {
        // 监听事件
        tim.on(TIM.EVENT.MESSAGE_RECEIVED, eventHandler); // 接到讯息
        tim.on(TIM.EVENT.SDK_READY, SDKReadyHandler);
        tim.on(TIM.EVENT.SDK_NOT_READY, SDKNotReadyHandler);
        tim.on(TIM.EVENT.MESSAGE_MODIFIED, messageModifyHandler);
        tim.on(TIM.EVENT.MESSAGE_REVOKED, messageRevokedHandler);
        tim.on(TIM.EVENT.MESSAGE_READ_BY_PEER, messageReadByPeerHandler); // 对方已读讯息
        tim.on(
          TIM.EVENT.CONVERSATION_LIST_UPDATED,
          conversationListUpdatedHandler
        ); // 聊天室列表更新
        tim.on(TIM.EVENT.GROUP_LIST_UPDATED, groupListUpdatedHandler);
        tim.on(
          TIM.EVENT.GROUP_ATTRIBUTES_UPDATED,
          groupAttributesUpdateHandler
        );
        tim.on(TIM.EVENT.PROFILE_UPDATED, profileUpdatedHandler);
        tim.on(TIM.EVENT.BLACKLIST_UPDATED, blackListUpdatedHandler);
        tim.on(TIM.EVENT.FRIEND_LIST_UPDATED, friendListUpdatedHandler);
        tim.on(
          TIM.EVENT.FRIEND_GROUP_LIST_UPDATED,
          friendGroupListUpdatedHandler
        );
        tim.on(
          TIM.EVENT.FRIEND_APPLICATION_LIST_UPDATED,
          friendApplicationListUpdatedHandler
        );
        tim.on(TIM.EVENT.KICKED_OUT, kickedOutHandler);
        tim.on(TIM.EVENT.ERROR, errorHandler);
        tim.on(TIM.EVENT.NET_STATE_CHANGE, netStateChangeHandler);
        tim.on(TIM.EVENT.SDK_RELOAD, SDKReloadHandler);
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

// 确认SDK已启动
function SDKReadyHandler(res) {
  console.log("SDK_READY", res);
  store.dispatch("message/setTimLogin", null, { root: true });
  store.dispatch("message/getMsgList", null, { root: true });
}
function SDKNotReadyHandler(res) {
  console.log("SDK_NOT_READY", res);
}
function messageModifyHandler(res) {
  console.log("MESSAGE_MODIFIED", res);
}
function messageRevokedHandler(res) {
  console.log("MESSAGE_REVOKED", res);
}
function messageReadByPeerHandler(res) {
  console.log("MESSAGE_READ_BY_PEER", res);
  // store.dispatch('message/addMsgContent', res.data, {root: true})
}

// 聊天列表更新
function conversationListUpdatedHandler(res) {
  console.log("CONVERSATION_LIST_UPDATED", res);
  store.dispatch("message/setMsgList", res.data, { root: true });
}
function groupListUpdatedHandler(res) {
  console.log("GROUP_LIST_UPDATED", res);
}
function groupAttributesUpdateHandler(res) {
  console.log("GROUP_ATTRIBUTES_UPDATED", res);
}
function profileUpdatedHandler(res) {
  console.log("PROFILE_UPDATED", res);
}
function blackListUpdatedHandler(res) {
  console.log("BLACKLIST_UPDATED", res);
}
function friendListUpdatedHandler(res) {
  console.log("FRIEND_LIST_UPDATED", res);
}
function friendGroupListUpdatedHandler(res) {
  console.log("FRIEND_GROUP_LIST_UPDATED", res);
}
function friendApplicationListUpdatedHandler(res) {
  console.log("FRIEND_APPLICATION_LIST_UPDATED", res);
}
function kickedOutHandler(res) {
  console.log("KICKED_OUT", res);
}
function errorHandler(res) {
  console.log("ERROR", res);
}
function netStateChangeHandler(res) {
  console.log("NET_STATE_CHANGE", res);
}
function SDKReloadHandler(res) {
  console.log("SDK_RELOAD", res);
}

function logout() {
  tim.off(TIM.EVENT.MESSAGE_RECEIVED, eventHandler);
  tim.logout();
}

// 接到新讯息
function eventHandler(res) {
  console.log("eventHandler===========================================================================");
  console.log(res);
  let currentRoute = router.currentRoute;
  res.data.forEach((item) => {
    // 判断当下是不是在 此讯息的聊天室中
    if (
      (currentRoute.path.indexOf("/message/") > -1 &&
        currentRoute.params.userID === item.from) ||
      currentRoute.params.userID === item.to
    ) {
      store.dispatch("message/addMsgContent", [item], { root: true });
      store.dispatch("message/setMessageRead", item.conversationID, {
        root: true,
      });
    }

    switch (item.type) {
      case "TIMTextElem": //文字讯息
      case "TIMImageElem": //图片讯息
      case "TIMVideoElem": //影像讯息
      case "TIMAudioElem": //声音讯息
        break;
      // 订单讯息
      case "TIMCustomElem":
        console.log("receive", res);
        console.log("receive", JSON.parse(res.data[0].payload.data));
        // 更新订单状态
        store.dispatch("orders/getLogId", res.data[0].payload.data.orderId, {
          root: true,
        });
        store.dispatch(
          "orders/getLog",
          {
            page: 1,
            limit: 10,
            status: 0,
            type: 0,
          },
          {
            root: true,
          }
        );
        store.dispatch("buy/getBuyTraders", {
          root: true,
        });

        // 如果在订单相关页面 要依状态转跳
        if (
          currentRoute.path.indexOf("/sellerConfirmation") > -1 ||
          currentRoute.path.indexOf("/waitingForBuyersPayment") > -1
        ) {
          let payload = JSON.parse(res.data[0].payload.data);
          console.log(payload);
          if (
            payload.traderTransactionStatus == 7 ||
            payload.traderTransactionStatus == 5 ||
            payload.traderTransactionStatus == 1 ||
            payload.traderTransactionStatus == 6 ||
            payload.traderTransactionStatus == 4
          ) {
            router.push({
              path: "/orderInfo",
              query: { orderId: payload.orderId },
            });
          } else if (payload.generationMethod == 0) {
            if (
              payload.traderTransactionStatus == 2 ||
              payload.traderTransactionStatus == 3
            ) {
              window.location.reload();
            }
          }
        }
        break;
    }
  });
}

export default {
  init,
  logout,
};
