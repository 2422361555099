import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

/* Router Modules */
import myRouter from "./modules/my";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "hash",
  routes: [
    {
      path: "/",
      name: "home",
      meta: { name: "首頁" },
      component: () => import("../views/home/index")
    },
    {
      path: "/bindMobile",
      name: "bindMobile",
      meta: { name: "绑定手机号" },
      component: () => import("../views/bindMobile/index")
    },
    {
      path: "/announcement",
      name: "announcement",
      meta: { name: "公告" },
      component: () => import("../views/announcement/index")
    },
    {
      path: "/orderInformation",
      name: "orderInformation",
      meta: { name: "訂單資訊" },
      component: () => import("../views/orderInformation/index")
    },
    {
      path: "/sellerConfirmation",
      name: "sellerConfirmation",
      meta: { name: "等待卖家确认" },
      component: () => import("../views/sellerConfirmation/index")
    },
    {
      path: "/waitingForBuyersPayment",
      name: "waitingForBuyersPayment",
      meta: { name: "等待买家付款" },
      component: () => import("../views/waitingForBuyersPayment/index")
    },
    {
      path: "/cancelOrder/:orderNo",
      name: "cancelOrder",
      meta: { name: "订单取消" },
      component: () => import("../views/cancelOrder/index")
    },
    {
      path: "/orderInfo",
      name: "orderInfo",
      meta: { name: "订单资讯" },
      component: () => import("../views/orderInfo/index")
    },
    {
      path: "/announcementDetails/:cId",
      name: "announcementDetails",
      meta: { name: "公告详情" },
      component: () => import("../views/announcementDetails/index")
    },
    {
      path: "/bannerContent/:cId",
      name: "bannerContent",
      meta: { name: "广告内容" },
      component: () => import("../views/bannerContent/index")
    },
    {
      path: "/login",
      name: "bannerContent",
      meta: { name: "登陸" },
      component: () => import("../views/login")
    },
    {
      path: "/order",
      name: "order",
      meta: { name: "订单" },
      component: () => import("../views/order")
    },
    {
      path: "/userAppeal/:orderId",
      name: "userAppeal",
      component: () => import("@/views/appeal")
    },
    {
      path: "/appealDetail",
      name: "appealDetail",
      meta: { name: "申诉详情" },
      component: () => import("../views/appealDetail")
    },
    {
      path: "/integralExchange",
      name: "integralExchange",
      meta: { name: "积分兑换" },
      component: () => import("../views/integralExchange")
    },
    {
      path: "/integralExchangeConfirm",
      name: "integralExchangeConfirm",
      meta: { name: "积分兑换" },
      component: () => import("../views/integralExchangeConfirm")
    },
    {
      path: "/bindOTP",
      component: () => import("../views/bindOTP/layout"),
      redirect: "/bindOTP/download",
      children: [
        {
          path: "download",
          component: () => import("../views/bindOTP/download"),
          meta: { name: "下载并安装" }
        },
        {
          path: "bind",
          component: () => import("../views/bindOTP/bind"),
          meta: { name: "备份密钥" }
        },
        {
          path: "confirm",
          component: () => import("../views/bindOTP/confirm"),
          meta: { name: "二次验证" }
        },
        {
          path: "help",
          component: () => import("../views/bindOTP/help"),
          meta: { name: "谷歌验证教程" }
        },
        {
          path: "register",
          component: () => import("../views/bindOTP/register"),
          meta: { name: "新用户注册" }
        },
        {
          path: "resetPassword",
          component: () => import("../views/bindOTP/resetPassword"),
          meta: { name: "重置密码" }
        },
        {
          path: "/message",
          name: "message",
          component: () => import("../views/message/index"),
          meta: { name: "消息列表" }
        },
        {
          path: "/message/:name/:conversationID/:userID",
          name: "message",
          component: () => import("../views/message/chart")
        }
      ]
    },
    myRouter,
    { path: "*", redirect: "/" }
  ]
});

router.beforeEach((to, from, next) => {
  console.log('router')
  if (to.path === '/login' && from.name == "my" && from.query.orderNo != '') {
    const orderNo = from.query.orderNo; // 從 URL 中讀取 orderNo 參數
    if (orderNo) {
      // 在使用者尚未登入時存儲 orderNo，可以使用 Vuex 或其他狀態管理工具
      store.dispatch('orders/setOrderNo', orderNo);
    }
  }
  if (to.path.indexOf("bindOTP") >= 0) {
    if (from.path !== "/login" && from.path.indexOf("bindOTP") < 0)
      next("/login");
    else next();
  } else next();
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
