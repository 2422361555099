const spinner = {
    namespaced: true,
    state: () => ({
        isLoading: false,
    }),
    mutations: {
        setIsLoading(state, status) {
            state.isLoading = status;
        },
    },
    actions: {
        changeSpinnerStatus({ commit }, status) {
            commit("setIsLoading", status);
        }
    },
};

export default spinner;
