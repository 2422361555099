<template>
  <div id="app">
    <router-view />
    <div class="bindMobilePopup" v-if="isMobilePopup == true">
      <div class="zone">
        <div class="title">请绑定手机号</div>
        <div class="box__check">
          <div @click="no()">取消</div>
          <div @click="go()">去设置</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import cookie from "js-cookie";
import tim from "@/utils/tim";
export default {
  name: "App",
  components: {},
  data() {
    return {
      isMobilePopup: false,
    };
  },
  computed: {
    ...mapState("visitorUnauthorized", ["visitorUnauthorized"]),
  },

  watch: {
    visitorUnauthorized: {
      handler(e) {
        if (e == true) {
          this.isMobilePopup = true;
          this.changeVisitorUnauthorized(false);
        }
      },
      immediate: true,
    },
  },
  created() {

    if (cookie.get("token")) {
      this.getSignalData({ signalNo: this.token }).then((res) => {
        tim.init({ userID: res.data.id, userSig: res.data.genSig });
      });
    }

  },
  mounted() {
    if (this.visitorUnauthorized == true) {
      this.isMobilePopup = true;
    }
  },

  methods: {
    ...mapActions("message", ["getSignalData"]),
    ...mapActions("visitorUnauthorized", ["changeVisitorUnauthorized"]),
    go() {
      this.isMobilePopup = false;
      this.changeVisitorUnauthorized(false);
      this.$router.push("/bindMobile");
    },
    no() {
      this.changeVisitorUnauthorized(false);
      this.isMobilePopup = false;
    },
  },
  destroyed() { },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.bindMobilePopup {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;

  .zone {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 320px;
    height: 120px;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 10px;

    .title {
      color: gray;
      text-align: center;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .box__check {
      display: flex;
      width: 100%;
      justify-content: space-around;
      align-items: center;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding-top: 16px;
      padding-bottom: 16px;

      div:nth-child(1) {
        width: 50%;
      }

      div:nth-child(2) {
        width: 50%;
        color: rgb(255, 115, 0);
      }
    }
  }
}
</style>
