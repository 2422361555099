

const security = {
	namespaced: true,
	state: () => ({
		visitorUnauthorized: false,
	}),
	mutations: {
		setVisitorUnauthorized(state, visitorUnauthorized) {
			state.visitorUnauthorized = visitorUnauthorized;
		},
	},
	actions: {
		changeVisitorUnauthorized({ commit }, status) {
			commit("setVisitorUnauthorized", status);
		}
	},
	getters: {},
};

export default security;
