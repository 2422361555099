import * as api from "@/api/dict";

const dict = {
  namespaced: true,
  state: () => ({
    dict: {
      "TradingChannel": [
        {
          "data": {
            "1": "支付宝",
            "2": "微信支付",
            "3": "银行卡"
          },
          "name": "channelId",
          "desc": "收款通道"
        },
        {
          "data": {
            "alipay": "支付宝",
            "wechatpay": "微信支付",
            "bankcard": "银行卡"
          },
          "name": "channelCode",
          "desc": "收款通道"
        }
      ],
      "Poster": [
        {
          "data": {
            "0": "背投广告",
            "1": "全屏广告",
            "2": "通栏广告",
            "3": "弹出式广告",
            "4": "按钮广告"
          },
          "desc": "广告类型",
          "name": "posterType"
        }
      ],
      "AnnouncementConfig": [
        {
          "data": {
            "0": "紧急",
            "1": "高",
            "2": "普通"
          },
          "desc": "公告优先级",
          "name": "priority"
        }
      ],
      "UserAppeal": [
        {
          "data": {
            "0": "未收到付款",
            "1": "收到的付款少了",
            "2": "收到的付款多了"
          },
          "desc": "申诉原因",
          "name": "appealType"
        },
        {
          "data": {
            "0": "待处理",
            "1": "申诉成功-订单取消",
            "2": "申诉失败-订单完成",
            "3": "用户撤销"
          },
          "desc": "申诉进度",
          "name": "status"
        },
        {
          "data": {
            "0": "提现",
            "1": "充值"
          },
          "desc": "订单类型",
          "name": "traderOrderType"
        },
        {
          "data": {
            "0": "待支付",
            "1": "已完成",
            "2": "等待对方支付",
            "3": "支付待确认",
            "4": "超时关闭",
            "5": "超时自动完成",
            "6": "常驻用户取消",
            "7": "用户取消",
            "8": "申诉关闭"
          },
          "desc": "交易状态",
          "name": "orderStatus"
        }
      ],
      "ImType": [
        {
          "data": {
            "1": "用户充值，下单成功，等待用户付款。",
            "2": "订单被用户标记为已付款，请等待常驻用户确认收款。",
            "3": "订单被用户标记为已取消，如有疑问，请联系客服。",
            "4": "订单因用户支付超时已被系统取消，如有疑问，请联系客服。",
            "5": "常驻用户已确认收到付款，充值金额已发放到用户账户。",
            "6": "系统提示：常驻用户已确认收到付款，充值金额已发放到用户账户。",
            "10": "用户提现，下单成功，等待常驻用户付款。",
            "11": "常驻用户将订单标记为已付款，请等待用户确认收款。",
            "12": "订单被常驻用户标记为已取消，如有疑问，请联系客服。",
            "13": "订单因常驻用户支付超时已被系统取消，如有疑问，请联系客服。",
            "14": "用户已确认收到付款，订单完成。",
            "15": "订单因用户确认收款超时，自动确认完成。",
            "16": "订单因用户未收到款提起申诉，请配合客服处理。",
            "19": "用户撤销申诉， 订单完成。",
            "20": "常驻用户撤销申诉， 订单完成。",
            "21": "订单因常驻用户未收到款提起申诉，请配合客服处理。",
            "22": "用户申诉已处理，订单已关闭，如有疑问，请联系客服。",
            "23": "用户申诉已关闭，订单已完成，如有疑问，请联系客服。",
            "24": "常驻用户申诉已处理，订单已完成，如有疑问，请联系客服。",
            "25": "常驻用户申诉已关闭，订单已取消，如有疑问，请联系客服。",
            "26": "用户提现，下单成功，请等待常驻用户付款。"
          },
          "desc": "im消息类型",
          "name": "imType"
        }
      ],
      "UserTransactionStatus": [
        {
          "data": {
            "0": "待支付",
            "1": "已完成",
            "2": "等待对方支付",
            "3": "支付待确认",
            "4": "超时关闭",
            "5": "超时自动完成",
            "6": "常驻用户取消",
            "7": "用户取消",
            "8": "申诉关闭"
          },
          "desc": "交易状态",
          "name": "status"
        }
      ],
      "UserAmountLog": [
        {
          "data": {
            "0": "支出",
            "1": "收入"
          },
          "desc": "出入账类型",
          "name": "checkType"
        }
      ],
      "Problem": [
        {
          "data": {
            "0": "操作问题",
            "1": "数据问题",
            "2": "其他"
          },
          "desc": "问题类型",
          "name": "problemType"
        }
      ]
    }
  }),
  mutations: {
    setDict(state, dict) {
      state.dict = dict;
    }
  },
  actions: {
    getDict({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .getDict()
          .then((res) => {
            if (res.code === "ok") {
              commit("setDict", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },
  getters: {},
};

export default dict;
