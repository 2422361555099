import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueClipboard from 'vue-clipboard2'
// import MarqueeText from 'vue-marquee-text-component'
import ElementUI from 'element-ui';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'element-ui/lib/theme-chalk/index.css';
import "./styles/common.scss";
import 'swiper/css/swiper.css'
import * as filters from './filter'
import './registerServiceWorker'
import './styles/tailwind.css'

// 跑馬燈
// Vue.component('marquee-text', MarqueeText)
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
// 複製
Vue.use(VueClipboard)

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
