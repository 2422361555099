import axios from "../axios/axios";

export function getUsers(params) {
  return axios.get("/user/users", { params });
}

export function postProductsId(params) {
  return axios.post("/user/products/" + params);
}

export function updateUsers(data) {
  return axios.put('/user/users', data)
}
// 修改个人资讯
export function editUserInfo(data) {
  return axios.put('/user/users', data)
}

